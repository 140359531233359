import React from 'react';
import classNames from 'classnames';
import Testimonial from '../atoms/testimonial';

const TestimonialSection = ({ className, testimonial }) => (
  <section className={classNames('testimonial-section', className)}>
    <div className="testimonial-section__inner container">
      <div className="testimonial-section__content-wrapper">
        <div className="testimonial-section__items">
          {testimonial.map(testimonialItem => {
            const { wordpress_id: id } = testimonialItem;

            return (
              <div className="testimonial-section__item" key={id}>
                <Testimonial {...testimonialItem} />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  </section>
);

export default TestimonialSection;
