import React from 'react';
import Img from 'gatsby-image';
import getGraphqlImage from '../../utils/get-graphql-image';

const PageHero = ({ title, body, backgroundImage }) => {
  const imageSizes = getGraphqlImage(backgroundImage);

  return (
    <section className={`page-hero${body ? ' page-hero--extended' : ''}`}>
      <div className="page-hero__inner container">
        <div className="page-hero__content-wrapper">
          <div className="page-hero__content">
            <div className="page-hero__content-inner">
              <h1 className="page-hero__title">{title}</h1>
              {body && <div className="page-hero__body" dangerouslySetInnerHTML={{ __html: body }} />}
            </div>
          </div>
        </div>
      </div>
      <Img className="page-hero__background" fluid={imageSizes} src={imageSizes.src} loading="eager" />
    </section>
  );
};

export default PageHero;
