import React from 'react';
import classNames from 'classnames';

const AdditionalContent = ({ title, body, noBackground }) => (
  <section className={classNames('additional-content', { 'additional-content--no-background': noBackground })}>
    <div className="additional-content__inner container">
      <div className="additional-content__content-wrapper">
        <h2 className="additional-content__title">{title}</h2>
        <div className="additional-content__body" dangerouslySetInnerHTML={{ __html: body }} />
      </div>
    </div>
  </section>
);

export default AdditionalContent;
