import React from 'react';
import classNames from 'classnames';

const Intro = ({ className, title, body }) => (
  <section className={classNames('intro', className)}>
    <div className="intro__inner container">
      <div className="intro__content-wrapper">
        <div className="intro__content">
          <strong className="intro__title" dangerouslySetInnerHTML={{ __html: title }} />
          {body && <div className="intro__body" dangerouslySetInnerHTML={{ __html: body }} />}
        </div>
      </div>
    </div>
  </section>
);

export default Intro;
