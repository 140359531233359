import React from 'react';
import Img from 'gatsby-image';
import getGraphqlImage from '../../utils/get-graphql-image';

const Testimonial = ({ body, attribution, avatar }) => {
  const avatarImage = getGraphqlImage(avatar);

  return (
    <div className={`testimonial${avatarImage ? ' testimonial--has-avatar' : ''}`}>
      <blockquote className="testimonial__inner">
        <div className="testimonial__content">
          <div className="testimonial__body" dangerouslySetInnerHTML={{ __html: body }} />
          <strong className="testimonial__attribution">{attribution}</strong>
          <span className="testimonial__edge testimonial__edge--right" />
          <span className="testimonial__edge testimonial__edge--left" />
        </div>
      </blockquote>
      {avatarImage && (
        <Img
          className="testimonial__image"
          fluid={avatarImage}
          src={avatarImage.src}
          alt={`${attribution} avatar image`}
        />
      )}
    </div>
  );
};

export default Testimonial;
