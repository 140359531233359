import React from 'react';
import classNames from 'classnames';
import uniqid from 'uniqid';
import FAQ from '../atoms/faq';

const FAQs = ({ className, title, items }) => {
  return (
    <section className={classNames('faqs', className)}>
      <div className="faqs__inner container">
        <div className="faqs__content-wrapper">
          <div className="faqs__content">
            <h2 className="faqs__title">{title}</h2>
            {items && (
              <div className="faqs__items">
                {items.map(item => (
                  <div className="faqs__item" key={uniqid()}>
                    <FAQ {...item} />
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default FAQs;
