import React from 'react';
import classNames from 'classnames';
import Img from 'gatsby-image';
import { Link } from 'gatsby';
import getGraphqlImage from '../../utils/get-graphql-image';

const TextImageAside = ({ className, image, title, body, callToActionLink, callToActionText }) => {
  const imageSizes = getGraphqlImage(image);

  return (
    <section className={classNames('text-image-aside', className)}>
      <div className="text-image-aside__inner container">
        <div className="text-image-aside__content-wrapper">
          <div className="text-image-aside__content">
            <div className="text-image-aside__header">
              <h2 className="text-image-aside__title">{title}</h2>
              <Img className="text-image-aside__mobile-image" fluid={imageSizes} src={imageSizes.src} />
            </div>
            <div className="text-image-aside__body" dangerouslySetInnerHTML={{ __html: body }} />
            {callToActionLink && callToActionText && (
              <div className="text-image-aside__cta-area">
                <Link className="text-image-aside__cta button" to={callToActionLink}>
                  {callToActionText}
                </Link>
              </div>
            )}
          </div>
          <div className="text-image-aside__image-wrapper-desktop">
            <Img fluid={imageSizes} src={imageSizes.src} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default TextImageAside;
