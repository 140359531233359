import React, { Fragment } from 'react';
import ShowHide from './show-hide';
import Arrow from '../../images/icons/arrow.svg';

const FAQ = ({ question, answer }) => (
  <div className="faq">
    <ShowHide
      renderTrigger={(open, toggleShowHide) => {
        // const triggerText = !open ? openText : closeText;

        return (
          <Fragment>
            <button
              type="button"
              className={`faq__toggle ${open ? ' faq__toggle--active' : ''}`}
              onClick={toggleShowHide}
            >
              <Arrow className="faq__toggle-icon" />
              <h3 className="faq__question">{question}</h3>
              {/* <span className="primary-navigation__toggle-children-text">{triggerText}</span> */}
            </button>
          </Fragment>
        );
      }}
      renderContent={open => {
        return (
          <div className={`faq__answer-wrapper ${open ? ' faq__answer-wrapper--open' : ''}`}>
            <div className="faq__answer" dangerouslySetInnerHTML={{ __html: answer }} />
          </div>
        );
      }}
    />
  </div>
);

export default FAQ;
